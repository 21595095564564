<template>
  <div
    class="overflow-hidden text-bb-text-default bg-white rounded-lg shadow-md w-full h-100 divide-y divide-neutral-100"
  >
    <div v-if="noChartData">
      <div class="flex items-center gap-4 px-6 py-4">
        <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
          <ic-rank class="text-bb-brand-purple my-auto mx-auto h-full" />
        </div>
        <h5 class="h5 text-bb-text-default">Top Competitors</h5>
      </div>
      <div>
        <no-chart-data class="py-5" />
      </div>
    </div>
    <div v-else>
      <div class="grid grid-cols-12 px-6 py-4">
        <div class="col-span-5">
          <div class="flex items-center gap-4">
            <div class="h-10 w-10 rounded-lg bg-neutral-0 text-bb-brand-purple">
              <ic-rank class="text-bb-brand-purple my-auto mx-auto h-full" />
            </div>
            <h5 class="h5 text-bb-text-default">Top Competitors</h5>
          </div>
        </div>

        <div class="col-span-4 text-center my-auto">
          <p class="text-sm font-medium text-bb-text-default">Ad Position</p>
          <p>1&emsp;2&emsp;3&emsp;4+</p>
        </div>
        <div class="col-span-3 my-auto">
          <p class="text-sm font-medium text-bb-text-default text-center">Share of Ad Impressions</p>
        </div>
      </div>
      <div>
        <div
          v-for="(competitor, index) in topCompetitors"
          :key="competitor.value"
          class="hover:bg-bb-background-purple-0 hover:border-bb-brand-purple border-white border-l-4 duration-300 ease-linear"
        >
          <div class="grid grid-cols-12 px-4 h-16">
            <div class="col-span-5">
              <div class="flex items-center gap-4 my-auto h-full">
                <p class="font-normal text-sm text-bb-text-default">{{ index + 1 }}</p>
                <div>
                  <p class="font-medium text-sm text-bb-text-default">{{ competitor.competitor }}</p>
                  <p class="text-xs font-normal text-bb-neutral-gray">{{ competitor.url }}</p>
                </div>
              </div>
            </div>

            <div class="col-span-4 my-auto mx-auto">
              <div class="pr-2">
                <svg :id="`bar-chart-${competitor.competitor}-${index}`"></svg>
              </div>
            </div>
            <div class="col-span-3 my-auto text-center">
              <p class="text-sm font-medium pr-2">{{ competitor.marketSharePercentage }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcRank from '@/components/icon/brightbid/ic-rank.vue'
import { createRankSvg } from '@/views/site/search/competitor_monitoring/overview/create-rank'
import NoChartData from '@/views/site/search/competitor_monitoring/overview/cards/NoChartData.vue'

export default {
  name: 'TopCompetitors',
  components: { NoChartData, IcRank },
  props: {
    topCompetitors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      smallChart: null,
      competitorRankChart: [],
    }
  },
  computed: {
    noChartData() {
      return this.topCompetitors.length === 0 || !this.topCompetitors
    },
  },
  mounted() {
    const competitionCharts = this.topCompetitors.map(competitor => {
      const ranks = competitor.ranks

      const others = ranks.splice(3)
      const othersSum = others.reduce((accumulator, current) => accumulator + current.count, 0)
      const othersPercentage = others.reduce((accumulator, current) => accumulator + current.percentage, 0)
      ranks.push({
        rank: 4,
        count: othersSum,
        percentage: othersPercentage,
      })

      return {
        competitor: competitor.competitor,
        ranks: ranks,
      }
    })
    competitionCharts.map((chart, index) => {
      const id = `#bar-chart-${chart.competitor}-${index}`
      createRankSvg(id, chart.ranks)
    })
  },
}
</script>

<style scoped lang="scss"></style>
